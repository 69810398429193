import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Video from '../views/video.vue'
import Showu from '../views/Showu.vue'
import Handbook from '../views/Handbook.vue'
import Diagnostico from '../views/Diagnostico.vue'
import Courses from '../views/Courses.vue'
import Roles from '../views/Roles.vue'
import Contexto from '../views/Contexto.vue'
import QMS from '../views/QMS.vue'
import Revision from '../views/Revision.vue'
import Avance from '../views/Avance.vue'
import Tablero from '../views/Tablero.vue'
import Auditoria from '../views/Auditoria.vue'
import CreateTicket from '../views/Tickets.vue'
import Manuales from '../views/Manuales.vue'
import Chat from '../views/chat.vue'
import Gracias from '../views/Gracias.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/showu',
    name: 'Showu',
    component: Showu
  },
  {
    path: '/handbooks',
    name: 'Handbook',
    component: Handbook
  },
  {
    path: '/diagnostico',
    name: 'Diagnostico',
    component: Diagnostico
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/Roles',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/Contexto',
    name: 'Contexto',
    component: Contexto
  },
  {
    path: '/QMS',
    name: 'QMS',
    component: QMS
  },
  {
    path: '/Revision',
    name: 'Revision',
    component: Revision
  },
  {
    path: '/Avance',
    name: 'Avance',
    component: Avance
  },
  {
    path: '/Tablero',
    name: 'Tablero',
    component: Tablero
  },
  {
    path: '/Auditoria',
    name: 'Auditoria',
    component: Auditoria
  },
  {
    path: '/crearticket',
    name: 'CreateTicket',
    component: CreateTicket
  },
  {
    path: '/manuales',
    name: 'Manuales',
    component: Manuales
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/gracias',
    name: 'Gracias',
    component: Gracias
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
