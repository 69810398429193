<template lang="html">
<div class="uk-grid">
  <div class="uk-width-medium-1-3"></div>
  <div class="uk-width-medium-1-3">
    <div class="md-card">
    <div class="md-card-content">
      <label>Rol o función</label>
      <div class="md-input-wrapper md-input-filled">
          <select id="select_demo_2" class="md-input" v-model="user.role">
              <option value="" disabled="" selected="" hidden="">Rol o función</option>
              <option selected="" disabled="">Rol o función</option>
              <option value="líder de proyecto">Líder de proyecto</option>
              <option value="Director">Propietario / Director</option>
              <option value="Gerente de área">Gerente de área</option>
              <option value="Consultor">Consultor</option>
              <option value="Comprador">Comprador</option>
              <option value="Asistente">Asistente / Secretaria</option>
              <option value="Becario">Becario</option>
              <option value="Estudiante">Estudiante</option>
              <option value="Académico">Académico</option>
          </select><span class="md-input-bar "></span>
      </div>
      <br>
      <label>Plazo para la certificación</label>
      <div class="md-input-wrapper md-input-filled">
          <select id="select_demo_2" class="md-input" v-model="user.plazo">
            <option value="" disabled="" selected="" hidden="">Plazo de Certificación/Vigilancia</option>
            <option selected="" disabled="">Plazo de Certificación/Vigilancia</option>
            <option value="Urgente">Urgente(menos de 3 meses)</option>
            <option value="3 a 6 Meses">De 3 a 6 meses</option>
            <option value="Más de 6 meses">Más de 6 meses</option>
          </select><span class="md-input-bar "></span>
      </div>
      <br>
      <label>No. Trabajadores</label>
      <div class="md-input-wrapper md-input-filled">
          <select id="select_demo_2" class="md-input" v-model="user.trabajadores">
              <option value="" disabled="" selected="" hidden="">No. Trabajadores</option>
              <option selected="" disabled="">No. Trabajadores</option>
              <option value="1 a 10">1 a 10</option>
              <option value="11 a 50">11 a 50</option>
              <option value="51 a 250">51 a 250</option>
              <option value="251 en adelante ">251 en adelante </option>
          </select><span class="md-input-bar "></span>
      </div>
    </div>
    </div>
  </div>
  <div class="uk-width-medium-1-3"></div>
</div>
</template>

<script>
export default {
  name: 'formulario',
  props: {
    user: Object
  }
}
</script>

<style lang="css" scoped>
</style>
