<template>
    <aside id="sidebar_main" style="margin-top: 70px; z-index: 11000;">
    <div class="scroll-wrapper scrollbar-inner" style="position: relative;">
    <div class="scrollbar-inner scroll-content scroll-scrolly_visible" style="height: auto; margin-bottom: 0px; margin-right: 0px; max-height: 90%;">
    <div id="side_bar">
    <div style="background: transparent; height: 20px;">
    </div>
     <div id="menu_id" class="menu_section">
     <ul>
    <li id="mainmenu_indexlider" title="Inicio">
    <router-link to="/home">
    <span class="menu_icon">
    <i class="material-icons">
    home</i>
</span>
     <span class="menu_title">
     Inicio</span>
</router-link>
</li>
     <li id="mainmenu_video" title="Videos">
     <router-link to="/video">
    <span class="menu_icon">
    <i class="material-icons">
    videocam</i>
</span>
     <span class="menu_title">
     Videos de asesoría</span>
     </router-link>
</li>
     <li title="usuarios">
     <router-link to="/showu">
    <span class="menu_icon">
    <i class="material-icons">
    person</i>
</span>
    <span class="menu_title">
    Usuarios</span>
     </router-link>
</li>
     <li title="Handbooks"  >
     <router-link to="/handbooks">
    <span class="menu_icon">
    <i class="material-icons">
    assignment</i>
</span>
    <span class="menu_title">
    Handbook</span>
  </router-link>
</li>
     <li id="mainmenu_formularios/1" title="Diagnóstico"  >
     <router-link to="/Diagnostico">
    <span class="menu_icon">
    <i class="material-icons">
    find_in_page</i>
</span>
     <span class="menu_title">
     Diagnóstico</span>
</router-link>
</li>
     <li>
     <router-link to="/courses">
    <span class="menu_icon">
    <i class="material-icons">
    school</i>
</span>
     <span class="menu_title">
     Capacitación </span>
</router-link>
</li>
 <li id="mainmenu_formularios/8" title="Roles y Cargos"  >
 <router-link to="/Roles">
<span class="menu_icon">
<i class="material-icons">
group</i>
</span>
 <span class="menu_title">
 Roles y Cargos</span>
</router-link>
</li>
 <li id="mainmenu_formularios/2" title="Contexto"  >
 <router-link to="/Contexto">
<span class="menu_icon">
<i class="material-icons">
business</i>
</span>
 <span class="menu_title">
 Contexto</span>
</router-link>
</li>
 <li id="mainmenu_QMS">
 <router-link to="/QMS">
<span class="menu_icon">
<i class="material-icons">
folder_shared</i>
</span>
 <span id="activa_formulario_sso_qms" class="menu_title">
 Armaria DMS</span>
 </router-link>
</li>
 <li id="mainmenu_formularios/5" title="Revisión de Doctos"  >
 <router-link to="/Revision">
<span class="menu_icon">
<i class="material-icons">
border_color</i>
</span>
 <span class="menu_title">
 Revisión de Doctos</span>
</router-link>
</li>
 <li id="mainmenu_formularios/3" title="Avance del proyecto"  >
 <router-link to="/Avance">
<span class="menu_icon">
<i class="material-icons">
timer</i>
</span>
 <span class="menu_title">
 Avance del proyecto</span>
</router-link>
</li>
 <li id="mainmenu_formularios/objetivos" title="Objetivos">
<router-link to="/Tablero">
<span class="menu_icon">
<i class="material-icons">
gps_fixed</i>
</span>
<span class="menu_title">
Objetivos</span>
</router-link>

</li>
 <li id="mainmenu_formularios/4" title="Auditoría"  >
<router-link to="/Auditoria">
<span class="menu_icon">
<i class="material-icons">
assignment_turned_in</i>
</span>
 <span class="menu_title">
 Auditoría interna</span>
</router-link>
</li>
 <li title="Tickets">
 <router-link to="/crearticket">
<span class="menu_icon">
<i class="material-icons">
note_add</i>
</span>
 <span class="menu_title">
 Tickets/Soporte</span>
</router-link>
</li>
<li id="mainmenu_manuales" title="Chat">
<router-link to="/chat">
<span class="menu_icon">
<i class="material-icons">
question_answer</i>
</span>
<span class="menu_title">
Chat</span>
</router-link>
</li>
<!-- <li id="mainmenu_manuales" title="Ayuda">
 <router-link to="/manuales">
<span class="menu_icon">
<i class="material-icons">
help</i>
</span>
 <span class="menu_title">
 Ayuda</span>
 </router-link>
</li>-->
 <li>
 <a href="#" @click="close()">
<span class="menu_icon">
<i class="material-icons">
exit_to_app</i>
</span>
 <span class="menu_title">
 Cerrar sesión</span>
 </a>
</li>
 <li class="uk-visible-large" style="background-color: rgb(237, 240, 242); width: 100%;">
 <a href="#" id="sidebar_mini_cambiar">
<i id="flecha_cambia_menus" class="material-icons md-36" style="float: right;">
chevron_left</i>
</a>
</li>
</ul>
</div>
</div>

</div>
<div class="scroll-element scroll-x scroll-scrolly_visible">
<div class="scroll-element_outer">
<div class="scroll-element_size">
</div>
<div class="scroll-element_track">
</div>
<div class="scroll-bar" style="width: 89px;">
</div>
</div>
</div>
<div class="scroll-element scroll-y scroll-scrolly_visible">
<div class="scroll-element_outer">
<div class="scroll-element_size">
</div>
<div class="scroll-element_track">
</div>
</div>
</div>
</div>
</aside>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    close: Function
  }
}
</script>
