<template>
  <div id="page_content">
    <div id="page_content_inner" style="padding-top: 48px !important;">
      <cot-button/>
        <div class="uk-grid uk-grid-width-small-1-2 uk-grid-width-large-1-4 uk-grid-width-xlarge-1-4 uk-text-center uk-sortable sortable-handler" id="dashboard_sortable_cards" data-uk-grid-margin="">
                <div class="uk-row-first">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#2980b9 !important;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/Diagnostico"><span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">find_in_page</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Diagnóstico
                                </h3>
                            </div>
                           El diagnóstico GAP (Good, Average, Poor / Bueno, regular, Malo) nos permite conocer el grado en que las prácticas de Tu Empresa cumplen con los requisitos de la Norma.
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#9bbb59;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/courses">
                                    <span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">school</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Capacitación
                                </h3>
                            </div>
                            Este módulo permite inscribir al personal que hayas dado de alta como Usuarios en IBIZA, a los cursos de capacitación de la norma o normas a implantar, certificar, mantener o actualizar en tu Empresa.
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#f39c12;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/Contexto"> <span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">business</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                   Contexto
                                </h3>
                            </div>
                            <p style="font-size: 84%;">Este módulo contiene Test de evaluación para conocer los factores internos y externos relevantes en tu Empresa, que pueden afectar su capacidad para lograr los resultados previstos de su Sistema de Gestión; así como para realizar su actualización cada que sea requerido por tu Empresa.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="md-card md-card-hover md-card-overlay" style="background:#c0392b;">
                        <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/QMS"> <span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">folder_shared</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                   Armaria DMS                                </h3>
                            </div>
                            <p>En este módulo puedes gestionar toda la documentación de tu Sistema de Gestión para mantenerla actualizada y hacerla disponible a todo el personal que necesite consultarla y utilizarla.</p> </div>
                    </div>
                </div>
                <div class="uk-grid-margin uk-row-first">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#41b176;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/Avance"> <span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">timer</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Avance del proyecto
                                </h3>
                            </div>
                            Con las minutas podemos realizar en conjunto el seguimiento puntual de las tareas, para evitar atrasos innecesarios en tu proyecto.
                        </div>
                    </div>
                </div>
                <div class="uk-grid-margin">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#954f72;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                               <router-link to="/Auditoria"> <span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">assignment_turned_in</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Auditoría interna
                                </h3>
                            </div>
                           <p style="font-size: 84%;">Este módulo tiene como propósito orientar a los auditores internos en la realización de las auditorías a tu Sistema de Gestión, mediante la revisión de los documentos y evidencias generadas por el personal de tu Empresa.</p>
                        </div>
                    </div>
                </div>
                <div class="uk-grid-margin">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#ba7609;">
                       <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                                <router-link to="/crearticket"><span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">note_add</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Tickets/Soporte                                </h3>
                            </div>
                            Con los tickets tú y tu personal pueden solicitar asistencia a nuestro Equipo de soporte técnico, para resolver incidentes relacionadas con las funcionalidades de IBIZA.
                        </div>
                    </div>
                </div>
                <div class="uk-grid-margin">
                    <div class="md-card md-card-hover md-card-overlay" style="background:#1f608b;">
                        <div class="md-card-content">
                            <div class="epc_chart" data-percent="37" data-bar-color="#9c27b0">
                                <router-link to="#"><span class="epc_chart_icon"><i class="material-icons" style="color: #ffffff !important;">computer</i></span></router-link>
                            <canvas height="110" width="110"></canvas></div>
                        </div>
                        <div class="md-card-overlay-content">
                            <div class="uk-clearfix md-card-overlay-header">
                                <i class="md-icon material-icons md-card-overlay-toggler">more_vert</i>
                                <h3>
                                    Asesoría en Vivo
                                </h3>
                            </div>
                           <p style="font-size: 84%;"> Aquí puedes solicitar sesiones de consultoría por videoconferencia con nuestros expertos. Cada semana, una vez recibidas las solicitudes, se programan sesiones en vivo para atenderlas, en coordinación con el Líder de tu proyecto.</p>
                        </div>
                    </div>
                </div>
            </div>
   </div>
</div>
<!--<div id="page_content">
  <div id="page_content_inner">
    <div class="md-card uk-margin-medium-bottom">
      <div class="md-card-content">
          <div class="videoWrapper"><iframe src="https://drive.google.com/file/d/10HCML4K5JaCYkSX_J-sy_i1vbDh2UZVH/preview" width="800px" height="450px" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>
      </div>
    </div>
  </div>
</div>-->

</template>

<script>
import cotButton from '../components/cotizacionButton.vue'
export default {
  components: {
    cotButton
  }
}
</script>
