<template lang="html">
<div v-if="displayQuotation" class="uk-alert uk-alert-large" data-uk-alert>
    <a href="" class="uk-alert-close uk-close"></a>
      <button class="uk-button uk-button-primary" @click="quotation()">Solicita una Cotización</button>
    <p>Obten una cotización personalizada y recibe atención inmediata.</p>
</div>
</template>

<script>
export default {
  data () {
    return {
      displayQuotation: true
    }
  },
  mounted () {
    this.checkCookie()
  },
  methods: {
    quotation: function () {
      this.$store.dispatch('showQuote')
    },
    checkCookie: function () {
      var cookie = this.getCookie()
      if (cookie !== false) {
        this.displayQuotation = false
      } else {
        this.displayQuotation = true
      }
    },
    getCookie: function () {
      var cname = 'demo_nq'
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
