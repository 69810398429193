<template>
<div id="page_content">
  <div id="page_content_inner">
   <cot-button/>
   <video-container section="Handbook"/>
  </div>
</div>
</template>

<script>
import videoContainer from '../components/videoContainer.vue'
import cotButton from '../components/cotizacionButton.vue'
export default {
  components: {
    videoContainer,
    cotButton
  }
}
</script>
