<template lang="html">
  <div class="quotation-div">
    <h3 v-if="type === 'standards'" class="uk-text-center">Selecciona la norma(s) de tu interés:</h3>
    <h3 v-if="type === 'services'" class="uk-text-center">Elije el servicio de tu interés:</h3>
    <h3 v-if="type === 'form'" class="uk-text-center">Estás a un solo paso</h3>
    <cards :cards="cardArray"
    :check="checkBox"
    />
  <inputs-form v-if="type == 'form'" :user="user"/>
  <buttons-form
  :buttonStandards="buttonStandards"
  :buttonServices="buttonServices"
  :buttonForm="buttonForm"
  :cardShow="cardShow"
  :type="type"
  :post="post"
  />
  </div>
</template>

<script>
import cards from './cotizaciones/Cards.vue'
import buttonsForm from './cotizaciones/buttonsForm.vue'
import inputsForm from './cotizaciones/inputsForm.vue'
export default {
  data () {
    return {
      standards: [
        { id: 'ISO 9001', name: 'ISO 9001', checked: false },
        { id: 'IATF 16949', name: 'IATF 16949', checked: false },
        { id: 'ISO 22000', name: 'ISO 22000', checked: false },
        { id: 'ISO 14001', name: 'ISO 14001', checked: false },
        { id: 'ISO 45001', name: 'ISO 45001', checked: false },
        { id: 'ISO 27001', name: 'ISO 27001', checked: false },
        { id: 'ISO 37001', name: 'ISO 37001', checked: false },
        { id: 'ISO 39001', name: 'ISO 39001', checked: false },
        { id: 'ISO 50000', name: 'ISO 50001', checked: false },
        { id: 'ISO 20000', name: 'ISO 20000', checked: false }
      ],
      services: [
        { id: 'Implantación', name: 'Implementación y certificación', checked: false },
        { id: 'Mantenimiento', name: 'Mantener la certificación', checked: false },
        { id: 'Cursos Máster', name: 'Cursos Máster', checked: false }
      ],
      user: {
        id: '',
        name: '',
        company: '',
        standards: [],
        services: '',
        role: '',
        plazo: '',
        trabajadores: ''
      },
      type: '',
      cardArray: [],
      postUrl: 'https://ibizabmb.com/updatedemo'
    }
  },
  computed: {
    buttonStandards: function () {
      var count = this.standards.length
      for (var i = 0; i < count; i++) {
        if (this.standards[i].checked === true) {
          return true
        }
      }
      return false
    },
    buttonServices: function () {
      var count = this.services.length
      for (var i = 0; i < count; i++) {
        if (this.services[i].checked === true) {
          return true
        }
      }
      return false
    },
    buttonForm: function () {
      for (var key in this.user) {
        if (this.user[key] === null || this.user[key] === '') {
          return false
        }
      }
      return true
    }
  },
  mounted () {
    this.initUser()
    this.cardShow('standards')
  },
  methods: {
    cardShow: function (type) {
      this.cardArray = []
      if (type === 'standards') {
        this.type = 'standards'
        this.cardArray = this.standards
      } else if (type === 'services') {
        this.updateUser('standards')
        this.type = 'services'
        this.cardArray = this.services
      } else if (type === 'form') {
        this.updateUser('services')
        this.type = 'form'
        this.cardArray = []
      }
    },
    checkBox: function (i) {
      if (this.type === 'standards') {
        if (this.standards[i].checked) {
          this.standards[i].checked = false
        } else {
          this.standards[i].checked = true
        }
      } else {
        for (var k = 0; k < this.services.length; k++) {
          this.services[k].checked = false
        }
        this.services[i].checked = true
      }
    },
    initUser: function () {
      var cookie = this.getCookie()
      var user = cookie.split('||#||#||')
      this.user.name = user[1].replace(/_/g, ' ')
      this.user.company = user[2].replace(/_/g, ' ')
      this.user.id = user[3]
    },
    getCookie: function () {
      var cname = 'demo_session'
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return false
    },
    updateUser: function (type) {
      if (type === 'standards') {
        this.user.standards = []
        for (var i = 0; i < this.standards.length; i++) {
          if (this.standards[i].checked) {
            this.user.standards.push(this.standards[i].id)
          }
        }
      } else if (type === 'services') {
        this.user.services = ''
        for (var j = 0; j < this.services.length; j++) {
          if (this.services[j].checked) {
            this.user.services = this.services[j].id
          }
        }
      }
    },
    post: function () {
      var path = this.postUrl
      var method = 'post'
      var params = this.user
      const form = document.createElement('form')
      form.method = method
      form.action = path
      for (const key in params) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = params[key]
        form.appendChild(hiddenField)
      }
      document.body.appendChild(form)
      form.submit()
    }
  },
  components: {
    cards,
    buttonsForm,
    inputsForm
  }
}
</script>

<style lang="css" scoped>
.quotation-div {
  padding: 24px;
}
</style>
