<template>
    <header id="header_main" style="box-shadow:none; height: 70px; margin-left:0;"><meta http-equiv="Content-Type" content="text/html; charset=gb18030">
        <div class="header_main_content">
            <nav class="uk-navbar" style="padding-top:1px;">
                <a href="#" id="sidebar_main_toggle" class="sSwitch sSwitch_left  uk-hidden-large">
                    <span class="sSwitchIcon"></span>
                </a>
                                          <a href="#">
                         <img style="height: 51px;display:inline;margin-top: 7px;" id="logo_company" alt="" data-uk-tooltip="{cls:'long-text'}" title="cambia el logo de tu empresa" src="https://ibizabmb.com/images/ibiza-logo1.png">
                     </a>
                     <span class="uk-text-large uk-text-center uk-visible-large" style="font-size: 14px !important; position:absolute; font-size: 14px !important;position:absolute; margin-top: 8px;">
                                              {{company}} - Demo<br>
                                        {{user}}
                     </span>
                <div class="uk-navbar-flip">
                    <ul class="uk-navbar-nav user_actions">
                        <li><img id="logotipo_ibiza" style="height: 42px;width:auto;margin-top: 9px;" class="uk-visible-large" src="https://ibizabmb.com/images/bmb-consultores.svg" alt=""></li>
                                                <li><a href="#" id="full_screen_toggle" class="user_action_icon uk-visible-large"><i class="material-icons md-36 iconosmenu" style="vertical-align: -23px;"></i></a></li>
                        <li data-uk-dropdown="{mode:'click',pos:'bottom-right'}">
                            <a href="#" class="user_action_icon"><i class="material-icons md-36 iconosmenu" style="vertical-align: -23px;"></i><span class="uk-badge tickets_contados" id="tickets_contados">0</span></a>
                            <div class="uk-dropdown uk-dropdown-xlarge">
                                <div class="md-card-content">
                                    <ul class="uk-tab uk-tab-grid" data-uk-tab="{connect:'#header_alerts',animation:'slide-horizontal'}">
                                        <li class="uk-width-1-2 uk-active" aria-expanded="true"><router-link to="/crearticket" class="js-uk-prevent uk-text-small">Alertas <span class="uk-badge tickets_contados">0</span></router-link></li>
                                                                            <li class="uk-tab-responsive uk-active uk-hidden" aria-haspopup="true" aria-expanded="false"><a>Alertas </a><div class="uk-dropdown uk-dropdown-small" aria-hidden="true"><ul class="uk-nav uk-nav-dropdown"></ul><div></div></div></li></ul>
                                    <ul id="header_alerts" class="uk-switcher uk-margin">
                                        <li aria-hidden="false" class="uk-active">
                                            <ul id="notiticket" class="md-list md-list-addon"></ul>
                                            <div class="uk-text-center uk-margin-top uk-margin-small-bottom">
                                                <router-link to="/showticket" class="md-btn md-btn-flat md-btn-flat-primary js-uk-prevent">Ver todo<span class="uk-badge tickets_contados">0</span></router-link>
                                            </div>
                                        </li>
                                                                            </ul>
                                </div>
                            </div>
                        </li>
                        <li data-uk-dropdown="{mode:'click',pos:'bottom-right'}">
                            <a href="#" class="user_action_image">
                                <i id="icono_default" class="material-icons md-36 iconosmenu icono_default1" style="vertical-align: -25px; display: initial;">account_circle</i>
                            <div class="chat_user_avatar">
                                <img class="md-user-image" style="vertical-align: -25px; display: none;" id="foto_perfil_usuario">
                            </div>
                            </a>
                            <div class="uk-dropdown uk-dropdown-small">
                                <ul class="uk-nav js-uk-prevent">
                                                                        <!--<li><router-link to="/perfillider">Mi Perfil</router-link></li>
                                                                        <li><router-link to="/cambiarfotoperfil">Cambia foto de perfil</router-link></li>
                                                                                                            <li><router-link to="/databillingleader">Datos Contractuales</router-link></li>
                                                                                                            <li><router-link to="/manuales">Ayuda</router-link></li>-->
                                                                        <li><a href="#" @click="close()">Cerrar sesión <span class="menu_icon"><i class="material-icons">exit_to_app</i></span></a></li>
                                                                    </ul>
                            </div>
                        </li>
                                            </ul>
                </div>
            </nav>
        </div>
        <div class="header_main_search_form">
            <i class="md-icon header_main_search_close material-icons"></i>
            <form class="uk-form uk-autocomplete">
                <input type="text" class="header_main_search_input">
                <button class="header_main_search_btn uk-button-link"><i class="md-icon material-icons"></i></button>
            </form>
        </div>
    </header>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    close: Function,
    user: String,
    company: String
  }
}
</script>
