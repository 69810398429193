<template lang="html">
  <div class="">
    <router-view v-if="!quotation"/>
    <quotation v-else/>
  </div>
</template>

<script>
import quotation from './quotation.vue'
export default {
  components: {
    quotation
  },
  computed: {
    quotation () {
      return this.$store.state.quotation
    }
  }
}
</script>

<style lang="css" scoped>
</style>
