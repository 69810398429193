<template lang="html">
  <div class="md-card uk-margin-medium-bottom">
    <div class="md-card-content">
      <div class="videoWrapper">
        <video :src="url" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
  props: {
    section: String
  },
  computed: {
    url () {
      return this.$store.getters.getUrl(this.section)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
