<template>
<div class="login_page login_page_v2">
    <div class="uk-container uk-container-center">
        <div class="md-card">
          <form class="" action="https://ibizabmb.com/ibizademo" method="post">
            <div class="md-card-content padding-reset">
                <div class="uk-grid uk-grid-collapse">
                    <div class="uk-width-large-1-3 uk-hidden-medium uk-hidden-small">
                        <div class="login_page_info uk-height-1-1" style="background-image: url('https://ibizabmb.com/admin_ibiza/dist/assets/img/others/sabri-tuzcu-331970.jpg')">
                        </div>
                    </div>
                    <div class="uk-width-large-1-3 uk-width-medium-1-3 uk-container-center">
                        <div class="login_page_forms">
                            <div id="login_card">
                                <div id="login_form">
                                    <div class="login_heading">
                                        <div>
                                            <img src="https://ibizabmb.com/images/ibiza-logo1.png" alt="" width="50%" height="50%">
                                        </div>
                                    </div>
                                    <div><p>Personaliza tu experiencia <b>IBIZA BMB</b></p></div>
                                    <div class="uk-form-row">
                                        <label for="login_username">Nombre</label>
                                        <input class="md-input" type="text" name="name" v-model="name"/>
                                    </div>
                                    <div class="uk-form-row">
                                        <label for="login_username">Apellido</label>
                                        <input class="md-input" type="text" name="lastname" v-model="lastname"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-large-1-3 uk-width-medium-1-3 uk-container-center">
                        <div class="login_page_forms" style="margin-top:64px">
                            <div id="login_card">
                                <div id="login_form2">
                                        <div class="uk-form-row">
                                            <label for="login_username">Teléfono </label>
                                            <input class="md-input" name="phone" type="text" v-model="phone"/>
                                        </div>
                                        <div class="uk-form-row">
                                            <label for="login_username">Empresa</label>
                                            <input class="md-input" name="company" type="text" v-model="company"/>
                                        </div>
                                        <div class="uk-form-row">
                                            <label for="login_username">eMail</label>
                                            <input class="md-input" name="email" type="text" v-model="email"/>
                                        </div>
                                        <div class="uk-margin-medium-top">
                                            <button class="md-btn md-btn-primary md-btn-block md-btn-large" type="submit">Continuar</button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </form>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      name: '',
      lastname: '',
      company: '',
      phone: '',
      email: ''
    }
  },
  props: {
    check: Function
  }
}
</script>
