<template lang="html">
<div id="page_content">
<div id="page_content_inner">
<div class="md-card uk-margin-medium-bottom">
<div class="md-card-content">
    <h1 class="uk-text-center">GRACIAS</h1>
</div>
</div>
</div>
</div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
