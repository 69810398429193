import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Courses: 'https://player.vimeo.com/external/434161305.hd.mp4?s=4994c2bc74e8d9662fc82914283f43d19cfe815d&profile_id=175',
    Tablero: 'https://player.vimeo.com/external/434161128.hd.mp4?s=4d2c60c939fbf571cafe38bfbc2ece26b39e2ccb&profile_id=174',
    Tickets: 'https://player.vimeo.com/external/434161072.hd.mp4?s=ae72729a37ab56ee42753341eb4166314131c9ee&profile_id=174',
    Showu: 'https://player.vimeo.com/external/434161390.hd.mp4?s=5105fb779e5273b66484277fba56d244076e1d52&profile_id=174',
    Auditoria: 'https://player.vimeo.com/external/434161099.hd.mp4?s=588ee3b846e0166c5e6d5751d55dc73cf5a52a99&profile_id=174',
    Avance: 'https://player.vimeo.com/external/434161162.hd.mp4?s=77acaf1ff5793493779bad57ce1f7956e1a3c4ec&profile_id=174',
    Chat: 'https://player.vimeo.com/external/434161006.hd.mp4?s=5498a7ba78217f509343d507afd69abce197454a&profile_id=174',
    Contexto: 'https://player.vimeo.com/external/434161238.hd.mp4?s=d85913c430bcd607a3ad556119326c07c1d7ef21&profile_id=174',
    Diagnostico: 'https://player.vimeo.com/external/434161335.hd.mp4?s=6939877eb1abd5f5decfe7ed0bd35c51d04692a6&profile_id=174',
    Handbook: 'https://player.vimeo.com/external/434161366.hd.mp4?s=6c00ed0e0cb7641bcc12d856055f80e92252c49d&profile_id=174',
    Home: '',
    Manuales: '',
    Qms: 'https://player.vimeo.com/external/434161205.hd.mp4?s=2ac292bc7c47bb4f73c500d947525c7ab325389a&profile_id=174',
    Revision: 'https://player.vimeo.com/external/434161186.hd.mp4?s=d6a6f9b186e693da5e5989a9345639e1d1b407aa&profile_id=174',
    Video: 'https://player.vimeo.com/external/434121280.hd.mp4?s=057be15284fcd06b98ce9b184a78448ccbeb48ce&profile_id=174',
    Roles: 'https://player.vimeo.com/external/434161273.hd.mp4?s=1eaa5fa95197e0e412edfbfdcfb8c13e96995b34&profile_id=174',
    displaySide: '',
    quotation: false
  },
  getters: {
    getUrl: (state) => (id) => {
      var url = state[id]
      var html = url
      return html
    }
  },
  mutations: {
    showNav (state) {
      state.displaySide = ''
    },
    hideNav (state) {
      state.displaySide = 'display:none;'
    },
    showQuote (state) {
      state.quotation = true
      state.displaySide = 'display:none;'
    },
    hideQuote (state) {
      state.quotation = false
      state.displaySide = ''
    }
  },
  actions: {
    showQuote (context) {
      context.commit('showQuote')
    },
    hideQuote (context) {
      context.commit('hideQuote')
    }
  },
  modules: {
  }
})
