<template lang="html">
  <div class="uk-grid uk-grid-width-medium-1-5" data-uk-grid-margin>
              <div v-for="(card, i) in cards" v-bind:key="i" class="uk-grid-margin">
                  <div class="md-card md-card-hover" :class="checked(card.checked)" @click="check(i)">
                      <div class="md-card-content uk-text-center">
                          {{card.name}}
                      </div>
                  </div>
              </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    cards: Array,
    check: Function
  },
  methods: {
    checked: function (checked) {
      if (checked) {
        return 'selected-card'
      }
    },
    showServices: function () {
      alert('click al boton')
    }
  },
  components: {
  }
}
</script>

<style lang="css" scoped>
.selected-card {
  background: blue;
  color: white;
}
.md-card {
  height: 100%;
  cursor: pointer;
}
</style>
