<template lang="html">
<div class="uk-margin">

  <p v-if="type === 'standards'" class="uk-text-center">
    <a v-if="buttonStandards"
    class="md-btn md-btn-wave-light waves-effect waves-button waves-light md-btn-success"
    href="#" @click="cardShow('services')">
      Siguiente
    </a>
</p>

<p v-if="type === 'services'" class="uk-text-center">
  <a v-if="buttonStandards"
  class="md-btn md-btn-wave-light waves-effect waves-button waves-light md-btn-warning"
  href="#" @click="cardShow('standards')">
    Regresar
  </a>
  <a v-if="buttonServices"
  class="md-btn md-btn-wave-light waves-effect waves-button waves-light md-btn-success"
  href="#" @click="cardShow('form')">
    Siguiente
  </a>
</p>

<p v-if="type === 'form'" class="uk-text-center">
  <a v-if="buttonServices"
  class="md-btn md-btn-wave-light waves-effect waves-button waves-light md-btn-warning"
  href="#" @click="cardShow('services')">
    Regresar
  </a>
  <a v-if="buttonForm"
  class="md-btn md-btn-wave-light waves-effect waves-button waves-light md-btn-success"
  href="#" @click="post()">
    Enviar
  </a>
</p>
</div>
</template>

<script>
export default {
  name: 'Buttons',
  props: {
    buttonStandards: Boolean,
    buttonServices: Boolean,
    buttonForm: Boolean,
    cardShow: Function,
    type: String,
    post: Function
  },
  computed: {
    checkType: function (type) {
      if (this.type === type) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
