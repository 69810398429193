<template>
  <div id="app">
    <login v-if="!logged" :check="checkLogin"/>
    <inner-content v-else/>
    <div :style="displayLogged">
    <nav-bar :close="closeSession" :user="user" :company="company"/>
    <side-bar :close="closeSession" :style="displaySide"/>
    </div>
  </div>
</template>

<script>
import navBar from './components/navBar.vue'
import sideBar from './components/sideBar.vue'
import login from './components/login.vue'
import innerContent from './components/content.vue'
import axios from 'axios'
export default {
  name: 'App',
  data () {
    return {
      logged: false,
      displayLogged: 'display:none;',
      user: '',
      company: '',
      crm_id: ''
    }
  },
  components: {
    navBar,
    sideBar,
    login,
    innerContent
  },
  mounted () {
    this.isLogged()
  },
  computed: {
    displaySide () {
      return this.$store.state.displaySide
    }
  },
  methods: {
    isLogged: function () {
      var cookie = this.getCookie()
      if (cookie !== false) {
        this.displayLogged = ''
        this.logged = true
        var user = cookie.split('||#||#||')
        this.user = user[1].replace(/_/g, ' ')
        this.company = user[2].replace(/_/g, ' ')
        this.crm_id = user[3]
        if (this.$router.currentRoute.path === '/') {
          this.$router.push('home')
        }
      }
    },
    checkLogin: function (name, lastname, company, phone, email) {
      if (name !== '' && lastname !== '' && company !== '' && phone !== '' && email !== '') {
        var user = name + ' ' + lastname
        this.user = user.replace(/ /g, '_')
        this.company = company.replace(/ /g, '_')
        document.cookie = this.generateCookie()
        this.user = user.replace(/_/g, ' ')
        this.company = company.replace(/_/g, ' ')
        this.logged = true
        this.displayLogged = ''
        this.saveUser(name, lastname, company, phone, email)
      } else {
        alert('Llena los campos requeridos con tus datos')
      }
    },
    closeSession: function () {
      alert('Sesión Cerrada...')
    },
    makeid: function (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    oneYear: function () {
      var oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      return oneYearFromNow
    },
    getCookie: function () {
      var cname = 'demo_session'
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return false
    },
    generateCookie: function () {
      var hash = this.makeid(210) + '||#||#||' + this.user + '||#||#||' + this.company + '; expires=' + this.oneYear()
      var cookie = 'demo_session=' + hash
      return cookie
    },
    saveUser: function (name, lastname, company, phone, email) {
      axios.post('https://ibizabmb.com/ibizademo', {
        name: name,
        lastname: lastname,
        company: company,
        email: email,
        phone: phone
      })
        .then(function (response) {
          return response
        })
        .catch(function (error) {
          return error
        })
    }
  }
}
</script>
