import { render, staticRenderFns } from "./inputsForm.vue?vue&type=template&id=07ef7961&scoped=true&lang=html&"
import script from "./inputsForm.vue?vue&type=script&lang=js&"
export * from "./inputsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ef7961",
  null
  
)

export default component.exports